// src/views/coming_soon/ComingSoon.jsx
import React, { useState } from "react";
import { Box, Typography, Button, IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  ContentBox,
  CustomContainer,
  JoinBetaButton,
  SocialMediaContainer,
  TypographyStyle,
} from "./ComingSoonStyles";

function ComingSoon() {
  const [expanded, setExpanded] = useState(false);
  const handleJoinBetaClick = () => {
    const betaUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSdpfnmXOtZGoFG9TBMO7IHbiWFf646nhNzYDXdVUnzYo_WWlQ/viewform?usp=sf_link";
    window.open(betaUrl, "_blank");
  };
  const handleSocialClick = (url) => window.open(url, "_blank");
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <ContentBox>
      <CustomContainer>
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2rem",
              sm: "2.5rem",
              md: "3rem",
              lg: "3.5rem",
            },
          }}
        >
          Welcome to <strong>slothdrop.com</strong>
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            textAlign: "center",
            color: "#000",
            fontSize: {
              xs: "1rem",
              sm: "1.25rem",
              md: "1.5rem",
            },
          }}
        >
          Revolutionise Your Social Media Presence.
        </Typography>
        <Box sx={{ textAlign: "center", color: "#000" }}>
          <JoinBetaButton variant="contained" onClick={handleJoinBetaClick}>
            JOIN BETA!
          </JoinBetaButton>
        </Box>
        <TypographyStyle variant="body1">
          🔮 <strong>Elevate</strong> your social media with AI-powered
          automation by <strong>slothdrop.com</strong> <br />
          🚀 <strong>Save time</strong>, optimise engagement, & boost growth
          24/7! <p></p>As we fine-tune our platform, we’re inviting a select
          group to join our early access program. This is a unique opportunity
          to be at the forefront of social media innovation.
        </TypographyStyle>
        <Button
          endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={toggleExpanded}
          sx={{
            color: "#000",
            textTransform: "none",
            justifyContent: "start",
            mt: 2,
          }}
        >
          {expanded ? "Show Less" : "Learn More"}
        </Button>
        <Collapse in={expanded}>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Due to limited availability, beta access will be granted on a
            first-come, first-served basis, and not all applicants may secure a
            spot in this round. We appreciate your understanding and interest in
            being part of our early user community.
          </Typography>
        </Collapse>
      </CustomContainer>

      <SocialMediaContainer direction="row" spacing={4}>
        Follow us
        <IconButton
          onClick={() =>
            handleSocialClick("https://www.instagram.com/slothdrop.io")
          }
          color="inherit"
        >
          <InstagramIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>
      </SocialMediaContainer>
    </ContentBox>
  );
}

export default ComingSoon;
